<template>
  <div class="flex md6 xs12 xl4">

    <vac-card
      :title="$t(title)"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="AVERAGE BRANDS COVERAGE"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />

      <Actions
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />

      <div class="total-avr">{{ totalAvrComputed }}</div>

<!--      <va-chart :data="data"-->
<!--                :options='options'-->
<!--                :style="{height: '500px'}"-->
<!--                type="donut"/>-->
      <Doughnut
        :dataset-id-key="'label'"
        :height="500"
        :chart-options="chartOptions"
        :chart-data="data"
        :css-classes="'doughnut export'"
        :style="{height: '500px'}"
      />
    </vac-card>

  </div>

</template>

<script>
import VaChart from '../statistics-template/charts/va-charts/VaChart';
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'AvrCoverage',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    totalAvr: {
      type: [String, Number],
      default: "0",
    },
    title: {
      type: String,
      default: "Average Brands Coverage",
    },
  },
  components: {
    Actions,
    ExcelPreviewModal,
    Doughnut
  },
  data() {
    return {
      options: {
        plugins: {
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
            },
          },
        },
      },
      isPreview: false,
      activeSwitcherButton: 1
    };
  },
  computed: {
    totalAvrComputed() {
      return this.activeSwitcherButton === 1 ? this.totalAvr : `${Math.ceil(this.totalAvr * 100)}%`
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.label}: ${ttItem.parsed}`;
                } else {
                  let percentage = (ttItem.parsed * 100).toFixed(0) + '%';
                  return `${ttItem.label}: ${percentage}`;
                }
              }
            }
          },
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
            },
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          }
        }
      }
    },
    preparedData() {
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));

      const returnObj = {}
      returnObj.labels = [...labels]
      returnObj.labels.unshift("Average")
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }


      returnObj.datasets = datasets.map(data => {
        returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
        for (let key = 0; key < data.data.length; key++) {
          returnObj.maxLength[key + 1] = Math.max(returnObj.maxLength[key + 1], String(data.data[key]).length)
        }

        return {
          data: [(data.data.reduce((a, b) => (a + b)) / data.data.length).toFixed(3), ...data.data]
        }
      })
      if (this.activeSwitcherButton === 2) {
        returnObj.datasets[0].data[0] = parseFloat(returnObj.datasets[0].data[0])
        returnObj.datasets[0].data = returnObj.datasets[0].data.map(d => {
          return `${(d * 100).toFixed(0)}%`
        })
      }
      return returnObj;
    }
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Brand", "Coverage"];
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));

      datasets[0].data.forEach((info, index) => {
        const obj = {
          "Brand": labels[index],
          "Coverage": info,
        };
        customData.push(obj);
      });

      customData.push({
        "Brand": "Total",
        "Coverage": this.totalAvr,
      });

      customData.map(cd => {
        cd['Coverage'] = `${cd['Coverage'] * 100}%`
      })

      HelperExcel.exportToExcel({}, this.title, customLabels, customData);
    },
    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
};
</script>

<style lang="scss">
.total-avr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
  line-height: 1;
  font-weight: 500;
  color: #000000;
  z-index: 5;
}
.doughnut {
  height: 500px;
}
</style>
